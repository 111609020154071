import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import logo from '../images/logo.svg'
import {FaAlignRight} from 'react-icons/fa'

export default class Navbar extends Component {
    // Navbar is not open by default
    state={
        isOpen:false
    }
    handleToggle=()=>{
        this.setState({
            isOpen:!this.state.isOpen
        })
    }

    render() {
        return (
        <nav className="navbar">
            <div className="nav-center">
                <div className="nav-header">
                    <Link to="/">
                        <img src={logo} alt={this.props.appname} title={this.props.appname}/>
                    </Link>
                    <button type="button" className="nav-btn" onClick={this.handleToggle}>
                        <FaAlignRight className="nav-icon"/>
                    </button>
                </div>
                <ul className={this.state.isOpen?"nav-links show-nav":"nav-links"}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms">Rooms</Link></li>
                </ul>
            </div>
        </nav>
        )
    }
}
